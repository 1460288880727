'use strict';
var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./product/detail'));
});

$('.button-descricao-details').click(function (e) {
    if (!$('.button-descricao-details').hasClass('active')) {
        $('.button-descricao-details').addClass('active')
        $('.button-descricao-details-tec').removeClass('active')

        $('.button-descricao').addClass('active')
        $('.button-descricao-tec').removeClass('active')
    }
});

$('.button-descricao-details-tec').click(function (e) {
    if (!$('.button-descricao-details-tec').hasClass('active')) {
        $('.button-descricao-details-tec').addClass('active');
        $('.button-descricao-details').removeClass('active')

        $('.button-descricao-tec').addClass('active')
        $('.button-descricao').removeClass('active')
    }
});

$(document).ready(function () {
    function hideIfNull() {
        var spans = document.querySelectorAll('.button-descricao-tec span');

        spans.forEach(function (span) {
            var spanValue = span.innerText;
            var paragraph = span.parentNode;

            if (spanValue === 'null' || spanValue === 'undefined' || spanValue === '') {
                paragraph.classList.add('d-none');
            }
        });
    }

    hideIfNull();
});
function maskCep(inputCEP) { 
    Array.from(inputCEP).forEach(function (inputCEP) {
        inputCEP.addEventListener("input", function (e) {
            var value = e.target.value;
            value = value.replace(/\D/g, '');
            
            var formattedCEP = '';
            if (value.length <= 9) {
                formattedCEP += value.substring(0, 5);
                if (value.length > 5) {
                    formattedCEP += '-' + value.substring(5, 9);
                }
            }

            e.target.value = formattedCEP;
        });
    });
}
$(document).ready(function () {
    var inputCEP = document.getElementsByClassName("cepSearchInput");
    console.log(inputCEP);
    maskCep($(inputCEP))


    var cepSearchInputs = document.getElementsByClassName("cepSearchInput");
    for (var i = 0; i < cepSearchInputs.length; i++) {
        cepSearchInputs[i].addEventListener('keydown', function (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                var relatedButton = this.parentElement.querySelector(".cepSearchButton");
                relatedButton.click();
            }
        });
    }

    var cepSearchButtons = document.getElementsByClassName("cepSearchButton");
    $('.search-zipcode-PDP').on('input', function(e){ 
        if($(this).val().length === 9){ 
            $('#cepSearchButtonProduct').click()
        }
    })
    for (var i = 0; i < cepSearchButtons.length; i++) {
        cepSearchButtons[i].addEventListener('click', function (e) {

            e.preventDefault();
            var zipCode = $(this).siblings('.search-field-input').val().replace("-", "");
            var quantity = $(this).siblings('.quantity-select').val();
            var url = $(this).closest('.cep-search').data('url');
            url = url + '&zipCode=' + zipCode + '&quantity=' + quantity;

            var clickedButton = $(this);

            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    console.log(data);
                    var values = data.values;

                    var numValues = values ? values.length : 0;
                    var outrosProdutos = clickedButton.closest('.outrosProdutos');
                
                    if (numValues === 1) {
                        outrosProdutos.css("margin-bottom", "40px");
                    } else if (numValues === 2) {
                        outrosProdutos.css("margin-bottom", "80px");
                    } else if (numValues === 3) {
                        outrosProdutos.css("margin-bottom", "120px");
                    } else if (numValues === 4) {
                        outrosProdutos.css("margin-bottom", "160px");
                    } else if (numValues === 5) {
                        outrosProdutos.css("margin-bottom", "200px");
                    } else if (numValues === 6) {
                        outrosProdutos.css("margin-bottom", "240px");
                    } else {
                        outrosProdutos.css("margin-bottom", "20px");
                    }

                    if (!values) {
                        var shippingOptions = clickedButton.closest('.cep-search-container').find('.shippingOptions');
                        var cepSearchContainer = clickedButton.closest('.cep-search-container');
                        shippingOptions.css('display', 'none');
                        shippingOptions.find('.shippingOptions-name, .shippingOptions-value, .shippingOptions-days').empty();
                        cepSearchContainer.find('.invalidZipCode').css('display', 'flex');
                        cepSearchContainer.find('.invalidZipCode').text('Frete indisponível');
                        return;
                    }

                    var shippingOptions = clickedButton.closest('.cep-search-container').find('.shippingOptions');
                    var cepSearchContainer = clickedButton.closest('.cep-search-container');
                    shippingOptions.css('display', 'flex');
                    shippingOptions.find('.shippingOptions-name, .shippingOptions-value, .shippingOptions-days').empty();
                    cepSearchContainer.find('.invalidZipCode').css('display', 'none');
                    cepSearchContainer.find('.invalidZipCode').empty();

                    values.forEach(function (value) {
                        var description = value.description;
                        var itemValue = value.value;
                        var days = value.days;

                        var nameElement = $('<span>').text(description);
                        var valueElement = $('<span>').text(itemValue);
                        
                        shippingOptions.find('.shippingOptions-name').append(nameElement);
                        shippingOptions.find('.shippingOptions-value').append(valueElement);
                        
                        if (days !== undefined) {
                            var daysElement = $('<span>').text(days + ' dias úteis');
                            shippingOptions.find('.shippingOptions-days').append(daysElement);
                        }
                    });
                },
                error: function (data) {
                    console.error("Error", data);
                    var shippingOptions = clickedButton.closest('.cep-search').find('.shippingOptions');
                    shippingOptions.find('.shippingOptions-name, .shippingOptions-value, .shippingOptions-days').empty();
                    shippingOptions.find('.invalidZipCode').text('Frete indisponível');
                }
            });
        });
    }
    if($(inputCEP).val().length == 9) $('#cepSearchButtonProduct').click()
    if(inputCEP.val().length >= 9) console.log(cepSearchButtons);
    // Ordenar elementos quando o valor do select mudar
    $("#select-search, #select-search-mobile").on("change", function () {
        var option = $(this).val();
        ordenarElementos(option);
    });

    // Função para ordenar elementos
    function ordenarElementos(ordem) {
        var container = $(".otherProducts-list");
        var items = container.children('.otherProducts-component').get();

        items.sort(function (a, b) {
            var valorA = parseInt($(a).data('value'));
            var valorB = parseInt($(b).data('value'));

            if (ordem === 'asc') {
                return valorA - valorB;
            } else {
                return valorB - valorA;
            }
        });

        container.empty().append(items);
    }

    // Trigger para ordenar elementos na inicialização
    $("#select-search, #select-search-mobile").trigger("change", 'asc');
});
